import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5650e7b1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "avatar-upload" }
const _hoisted_2 = { for: "avatarUpload" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_AvatarContainer = _resolveComponent("AvatarContainer")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_list_header = _resolveComponent("ion-list-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Profil")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, { collapse: "condense" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, { size: "large" }, {
                    default: _withCtx(() => [
                      _createTextVNode("Profil")
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("input", {
              type: "file",
              accept: "image/*",
              id: "avatarUpload",
              onChange: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.compressImage && _ctx.compressImage(...args)))
            }, null, 32),
            _createElementVNode("label", _hoisted_2, [
              _createVNode(_component_AvatarContainer, {
                ref: "avatar",
                class: "ion-justify-content-center",
                id: this.currentUser.id
              }, null, 8, ["id"]),
              _createTextVNode(" Bearbeiten ")
            ])
          ]),
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode("Persönliche Angaben")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, { inset: true }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Vorname")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "text",
                    modelValue: _ctx.firstname,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.firstname) = $event)),
                    onIonBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.updateProfile('profiles','firstname',_ctx.firstname)))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Nachname")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "text",
                    modelValue: _ctx.lastname,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.lastname) = $event)),
                    onIonBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.updateProfile('profiles','lastname',_ctx.lastname)))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Geburtsdatum")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "date",
                    modelValue: _ctx.birth_date,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.birth_date) = $event)),
                    onIonBlur: _cache[6] || (_cache[6] = ($event: any) => (_ctx.updateProfile('profile_private_data','birth_date',_ctx.birth_date)))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.openNameModal('profiles', 'gender_id', 'gender_name', 'gender', 'Geschlecht')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Geschlecht")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.gender_name), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Land")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.country_name), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.lockClosed,
                    size: "small"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode("Kontaktangaben")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, { inset: true }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Telefon Nummer")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.phone), 1)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_icon, {
                    icon: _ctx.lockClosed,
                    size: "small"
                  }, null, 8, ["icon"])
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Festnetz Nummer")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_input, {
                    type: "text",
                    modelValue: _ctx.fixnet_number,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.fixnet_number) = $event)),
                    onIonBlur: _cache[9] || (_cache[9] = ($event: any) => (_ctx.updateProfile('profiles','fixnet_number',_ctx.fixnet_number))),
                    onIonChange: _cache[10] || (_cache[10] = ($event: any) => (_ctx.formatPhone(_ctx.fixnet_number, 'fixnet_number')))
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode("Arzt und Versicherung")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, { inset: true }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.openNameModal('profile_private_data', 'doctor_id', 'doctor_name' , 'doctor', 'Doktor')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Doktor")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.doctor_name), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[12] || (_cache[12] = ($event: any) => (_ctx.openNameModal('profile_private_data', 'basic_insurance_id', 'basic_insurance_name', 'health_insurances', 'Grundversicherung')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Versicherung")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.basic_insurance_name), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[13] || (_cache[13] = ($event: any) => (_ctx.openNameModal('profile_private_data', 'supplement_insurance_id', 'supplement_insurance_name', 'health_insurances', 'Zusatzversicherung')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Zusatzversicherung")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.supplement_insurance_name), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_label, null, {
                default: _withCtx(() => [
                  _createTextVNode("Konditionen")
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_list, { inset: true }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_item, {
                button: "",
                onClick: _cache[14] || (_cache[14] = ($event: any) => (_ctx.openNameModal('profile_private_data', 'allergie_id', 'allergie_name' , 'allergies', 'Allergien')))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode("Allergien")
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_note, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.allergie_name), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_button, {
            onClick: _cache[15] || (_cache[15] = ($event: any) => (_ctx.logoutUser())),
            expand: "block",
            class: "logout",
            color: "light"
          }, {
            default: _withCtx(() => [
              _createTextVNode("Logout")
            ]),
            _: 1
          }),
          _createVNode(_component_ion_loading, {
            "is-open": _ctx.loadingState.formSubmission,
            cssClass: "my-custom-class",
            message: "Please wait..."
          }, null, 8, ["is-open"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}