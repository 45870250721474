
  import {
      IonContent,
      IonHeader,
      IonTitle,
      IonToolbar,
      IonButtons,
      IonButton,
      modalController,
      IonItem,
      IonLabel,
      IonList,
      IonSearchbar,
      IonNote,
      IonAvatar,
  } from '@ionic/vue';
    import { defineComponent } from 'vue';
    import { supabase } from '@/supabase';

    export default defineComponent({
      props: {
        table: String,
        id: Number,
        title: String,
        filter: String,
        filterValue: Boolean,
      },
      data(){
        return{
          list: [] as any,
          filteredList: [] as any,
        }
      },
      async created(){
        console.log('id',this.id);
        if(!supabase) return;
        if(!this.table) return;
        
        if(this.filter){
          const { data, error } = await supabase
            .from(this.table)
            .select()
            .eq((this as any).filter, (this as any).filterValue)
          if(error) return console.log(error);
          if(data){
            this.list = data;
            this.filteredList = data;
          }  
        }
        else{
          const { data, error } = await supabase
            .from(this.table)
            .select()
          if(error) return console.log(error);
          if(data){
            this.list = data;
            this.filteredList = data;
          }         
        }
      },
      components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonItem, IonLabel, IonList, IonSearchbar, IonNote, IonAvatar },
      methods: {
        cancel() {
          return modalController.dismiss(null, 'cancel');
        },
        confirm(id:string):any {
          return modalController.dismiss(id, 'confirm');
        },
        search(event:any) {
          const query = event.target.value.toLowerCase();
          this.filteredList = this.list.filter((d:any) => d.name.toLowerCase().indexOf(query) > -1);
      },
      },
    });
  