
  import { defineComponent } from 'vue'
  import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonLabel, IonInput, IonButton, IonButtons, IonBackButton, IonLoading, modalController, IonListHeader, IonNote, IonIcon } from '@ionic/vue';
  import { User } from '@supabase/gotrue-js/src/lib/types';
  import { supabase } from '@/supabase';
  import AvatarContainer from '@/components/AvatarContainer.vue';
  import SelectModal from '@/components/SelectModal.vue';
  import { resizeImage } from "@/scripts/resize";
  import { lockClosed } from 'ionicons/icons';

  export default defineComponent({
    name: 'UserProfile',
    components: {
      AvatarContainer, IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonList, IonItem, IonLabel, IonInput, IonButton, IonButtons, IonBackButton, IonLoading, IonListHeader, IonNote, IonIcon
    },
    data() {
      return {
        email: '',
        phone: '',
        basic_insurance_id: '',
        basic_insurance_name: '',
        supplement_insurance_id: '',
        supplement_insurance_name: '',
        doctor_id: '',
        doctor_name: '',
        birth_date:'',
        fixnet_number: '',
        fixnet_number_formatted: '',
        firstname: '',
        lastname: '',
        country_id: '',
        country_name: '',
        gender_id: '',
        gender_name: '',
        allergie_id: '',
        allergie_name: '',
        loadingState: {
          formSubmission: false
        },
        currentUser: {} as User | null,
        profilePrivate: [] as any,
        profilePublic: [] as any,
      }
    },
    setup() {
      return {
        lockClosed
      }
    },
    mounted() {
      this.getProfile();
    },
    methods: {
      async getProfile() {
        if (!supabase) return;
        this.currentUser = supabase.auth.user();
        if (!this.currentUser) return;
        const { data: public_profile, error: public_error } = await supabase
          .from('profiles')
          .select()
          .eq('id', this.currentUser.id)
        const { data: private_profile, error: private_error } = await supabase
          .from('profile_private_data')
          .select()
          .eq('id', this.currentUser.id)

        if (public_error || private_error) {
          console.log('Error fetching profile', public_error || private_error)
          return
        }
        if (public_profile) {
          this.profilePublic = public_profile[0];
          this.fixnet_number = public_profile[0].fixnet_number;

          this.formatPhone(this.fixnet_number, 'fixnet_number');

          this.firstname = public_profile[0].firstname;
          this.lastname = public_profile[0].lastname;
          this.country_id = public_profile[0].country_id;
          this.convertId(this.country_id, 'country_name', 'countries');

          this.gender_id = public_profile[0].gender_id;
          this.convertId(this.gender_id, 'gender_name', 'gender');

        }
        if (private_profile) {
          this.profilePrivate = private_profile[0];
          this.basic_insurance_id = private_profile[0].basic_insurance_id;
          this.convertId(this.basic_insurance_id, 'basic_insurance_name', 'health_insurances');

          this.supplement_insurance_id = private_profile[0].supplement_insurance_id;
          this.convertId(this.supplement_insurance_id, 'supplement_insurance_name', 'health_insurances');

          this.doctor_id = private_profile[0].doctor_id;
          this.convertId(this.doctor_id, 'doctor_name', 'doctor');

          this.birth_date = private_profile[0].birth_date;

          this.allergie_id = private_profile[0].allergie_id;
          this.convertId(this.allergie_id, 'allergie_name', 'allergies');


        }
        if (this.currentUser.email) {
          this.email = this.currentUser.email;
        }
        if (this.currentUser.phone) {
          this.phone = this.currentUser.phone;
          this.formatPhone(this.phone, 'phone');

        }
      },
      async convertId(id:any, name:('doctor_name' | 'gender_name' | 'country_name' | 'basic_insurance_name' | 'supplement_insurance_name' | 'allergie_name'), table:string){
        if (!supabase) return;
        if (!id) return;
        const { data, error } = await supabase
          .from(table)
          .select()
          .eq('id', id)        
        if (error) {
          console.error(error);
          return
        }
        if (data) this[name] = data[0].name;
      },
      async openNameModal(idTable:string, id_name:('doctor_id' | 'gender_id' | 'country_id' | 'basic_insurance_id' | 'supplement_insurance_id' | 'allergie_id'),name:('doctor_name' | 'gender_name' | 'country_name' | 'basic_insurance_name' | 'supplement_insurance_name' | 'allergie_name'), table:string, title:string) {
        console.log('open selectModal', id_name, table);
        
        const modal = await modalController.create({
          componentProps: {
            table: table,
            id: this[id_name],
            title: title
          },
          component: SelectModal,
          canDismiss: true,
        });
        this.loadingState.formSubmission = false;

        modal.present();

        const { data, role } = await modal.onWillDismiss();

        if (role === 'confirm') {
          console.log(data);
          this.doctor_id = data;
          this.convertId(this.doctor_id, name, table);
          this.updateProfile(idTable, id_name, data);
          // Return id
        }
      },
      async updateProfile( location: string, column: string, value: any, ) {
        if (!supabase) return;
        this.currentUser = supabase.auth.user();
        if (!this.currentUser) return;

        const { data: public_data, error: public_error } = await supabase
          .from(location)
          .update({
            [column]: value,
          })
          .match({ 'id': this.currentUser.id });
        
          if (public_error) {
            console.log('Error updating profile', public_error)
            return
          }
      },
      async compressImage(event:any){
        const avatarFile = event.target.files[0]
        const avatarUrl = URL.createObjectURL(avatarFile);
        
        let img:any = await resizeImage(avatarUrl, {
          maxWidth: 512,
          maxHeight: 512,
          contentType: "image/jpeg",
          quality: .5
        });

        function dataURItoBlob(dataURI:any) {
          var byteString = atob(dataURI.split(',')[1]);
          var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
          }
          var blob = new Blob([ab], {type: mimeString});
          return blob;

        }
        this.updateAvatar(dataURItoBlob(img.url));
      },
      async updateAvatar(file:any) {
        // if (!event.target.files[0]){
        //   return;
        // }

        // const avatarFile = event.target.files[0]
        const avatarFile = file

        if (!supabase) return;

        this.loadingState.formSubmission = true
        this.currentUser = supabase.auth.user();
        if (!this.currentUser) return;
        const { data, error } = await supabase
          .storage
          .from('avatars')
          .upload(this.currentUser.id+'_avatar.png', avatarFile, {
            cacheControl: '3600',
            upsert: true
          })
          if (data !== null) {
            console.log('Avatar Image uploaded', data);
            this.setAvatarUrl();
            (this.$refs as any).avatar.getAvatarUrl()
            this.loadingState.formSubmission = false

          }
          if (error !== null) {
            console.log(error);
            this.loadingState.formSubmission = false

          }
      },
      async setAvatarUrl() {
        const time = new Date().toISOString().replaceAll('.','-').replaceAll(':','-')
        if (!supabase) return;
        if (!this.currentUser) return;
        const { data, error } = await supabase
          .from('profiles')
          // Fix this in future file upload system
          .update({ avatar_url: this.currentUser.id+'_avatar.png' })
          .eq('id', this.currentUser.id)
          if (data !== null) {
            console.log(data);
            console.log('Avatar URL updated');  
          }
          if (error !== null) {
            console.log(error);
          }
      },
      logoutUser() {
        if (!supabase) return;
        supabase.auth.signOut();
        this.$router.push('/login');
      },
      formatPhone(number:any, numberFormatted: any){
        const f = number
          .replaceAll('+', '')
          .replaceAll(' ', '')
        
        if (f.length === 11) {
          (this as any)[numberFormatted] = f.replace(/(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})/, "+$1 $2 $3 $4 $5")
        }
      },
    }
  })
  