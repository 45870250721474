import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-664a3ced"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_header, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_toolbar, null, {
          default: _withCtx(() => [
            _createVNode(_component_ion_buttons, { slot: "start" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_button, { onClick: _ctx.cancel }, {
                  default: _withCtx(() => [
                    _createTextVNode("Abbrechen")
                  ]),
                  _: 1
                }, 8, ["onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_title, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.title), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_ion_content, { class: "" }, {
      default: _withCtx(() => [
        (_ctx.list.length > 5)
          ? (_openBlock(), _createBlock(_component_ion_searchbar, {
              key: 0,
              debounce: 50,
              onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.search($event)))
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_list, null, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredList, (item) => {
              return (_openBlock(), _createBlock(_component_ion_item, {
                key: item.id,
                onClick: ($event: any) => (_ctx.confirm(item.id))
              }, {
                default: _withCtx(() => [
                  (item.flag_url)
                    ? (_openBlock(), _createBlock(_component_ion_avatar, {
                        key: 0,
                        slot: "start"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: item.flag_url
                          }, null, 8, _hoisted_1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  (item.phone_prefix)
                    ? (_openBlock(), _createBlock(_component_ion_note, {
                        key: 1,
                        slot: "end"
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode("+" + _toDisplayString(item.phone_prefix), 1)
                        ]),
                        _: 2
                      }, 1024))
                    : _createCommentVNode("", true),
                  _createVNode(_component_ion_label, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1024)
                ]),
                _: 2
              }, 1032, ["onClick"]))
            }), 128))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}