
  import { defineComponent } from 'vue';
  import {
    // loadingController,
    IonLoading,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonInput,
    IonButton,
    IonButtons,
    IonBackButton,
  } from '@ionic/vue';
  import { supabase } from "@/supabase"
  import { User } from '@supabase/gotrue-js/src/lib/types'
  import { Haptics, ImpactStyle } from '@capacitor/haptics';

  const hapticsImpactMedium = async () => {
    await Haptics.impact({ style: ImpactStyle.Medium });
  };

  export default defineComponent({
    name: 'LoginPage',
    components: {
      IonLoading,
      IonPage,
      IonHeader,
      IonToolbar,
      IonTitle,
      IonContent,
      IonInput,
      IonButton,
      IonButtons,
      IonBackButton,
    },
    data() {
      return {
        currentUser: null as User | null,
        briefs: [] as Array<any> | null,
        loginPhoneNumber: this.$route.query.phone as string,
        loginPhoneNumberFormatted: '',
        loginToken: '',
        formState: {
          type: 'success',
          content: '',
        },
        loadingState: {
          formSubmission: false,
        }
      }
    },
    watch: {
      currentUser(newVal) {
        console.log("watch",newVal);
      }
    },
    mounted() {
      this.checkForActiveUser();
      this.loginPhoneNumberFormatted = this.formatPhone(this.loginPhoneNumber);
    },
    methods: {
      checkForActiveUser() {
        if (!supabase) return;
        supabase.auth.onAuthStateChange((event, session) => {
          if (event === 'SIGNED_OUT') {
            console.log('state change: user logged out');
            this.currentUser = null;
          } else if (event === 'SIGNED_IN' && session) {
            console.log('state change: user logged in');
            this.currentUser = session.user;
          } else {
            console.log('new auth state change event captured: ');
            console.log(event, session);
          }
        })
      },
      otpLogin(){
        if (!supabase) return;
        this.loadingState.formSubmission = true;
        supabase.auth.verifyOTP({
            phone: this.loginPhoneNumber,
            token: this.loginToken,
        })
        .then(response => {
              if (response.error !== null) {
                throw response.error;
              } else {
                // this.formState.type = 'success';
                // this.formState.content = 'SMS sent successfully...';
                this.loadingState.formSubmission = false;
                hapticsImpactMedium();
                this.loginToken = '';
                this.checkUsername();
                // this.dismissLoading();
              }
            })
            .catch(error => {
              this.formState.type = 'error';
              this.formState.content = error.message ? error.message : error;
              this.loadingState.formSubmission = false;
              hapticsImpactMedium();
              // this.dismissLoading();
            });
      },
      async checkUsername(){
        if (!supabase) return;
        this.currentUser = supabase.auth.user();
        // console.log(this.currentUser);
        if (!this.currentUser) return;
        const { data, error } = await supabase
          .from('profiles')
          .select()
          .eq('id', this.currentUser.id)
        
        if (data){
          if (data[0].firstname !== "" && data[0].lastname !== "") {
            console.log("user has name");
            this.loadingState.formSubmission = false;

            this.$router.push('/');
            
          } else {
            console.log("user has NO name");
            this.loadingState.formSubmission = false;

            this.$router.push('/login/name');
          }
        }
        else{
          console.log(error);
        }
      },
      logoutUser() {
        if (!supabase) return;
        supabase.auth.signOut();
      },
      formatPhone(number:any){
        if(!number) return;

        const f = number
          .replaceAll('+', '')
          .replaceAll(' ', '')
        
        if (f.length === 11) {
          return f.replace(/(\d{2})(\d{2})(\d{3})(\d{2})(\d{2})/, "+$1 $2 $3 $4 $5")
        }
        else{
          return number;
        }
      },
    }
  })
  