import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a275c068"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ion-margin" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_back_button = _resolveComponent("ion-back-button")!
  const _component_ion_buttons = _resolveComponent("ion-buttons")!
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_loading = _resolveComponent("ion-loading")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_header, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_buttons, { slot: "start" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_back_button)
                ]),
                _: 1
              }),
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Login")
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", null, [
            _createVNode(_component_ion_header, { collapse: "condense" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_toolbar, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_title, { size: "large" }, {
                      default: _withCtx(() => [
                        _createTextVNode("SMS Code")
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createElementVNode("p", _hoisted_1, " Wir haben dir einen Verifizierungscode auf " + _toDisplayString(_ctx.loginPhoneNumberFormatted) + " gesendet.", 1),
            _createElementVNode("form", {
              onSubmit: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.otpLogin()), ["prevent"]))
            }, [
              _createVNode(_component_ion_input, {
                inputmode: "numeric",
                placeholder: "Token",
                modelValue: _ctx.loginToken,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.loginToken) = $event)),
                class: "ion-margin",
                onIonChange: _cache[1] || (_cache[1] = ($event: any) => {if(_ctx.loginToken.length === 6) _ctx.otpLogin();})
              }, null, 8, ["modelValue"]),
              _createElementVNode("p", {
                class: _normalizeClass(_ctx.formState.type)
              }, _toDisplayString(_ctx.formState.content), 3),
              _createVNode(_component_ion_button, {
                disabled: _ctx.loginToken.length < 6,
                type: "submit",
                expand: "block",
                color: "primary",
                class: "ion-margin"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Login ")
                ]),
                _: 1
              }, 8, ["disabled"])
            ], 32)
          ]),
          _createVNode(_component_ion_loading, {
            "is-open": _ctx.loadingState.formSubmission,
            cssClass: "my-custom-class",
            message: "Please wait..."
          }, null, 8, ["is-open"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}