
  import { defineComponent } from 'vue';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination } from 'swiper';
  import { Haptics, ImpactStyle } from '@capacitor/haptics';
  import 'swiper/css';
  import 'swiper/css/pagination';
  import { supabase } from "@/supabase"
  import { User } from '@supabase/gotrue-js/src/lib/types'
  import SelectModal from '@/components/SelectModal.vue';
  import {
    IonLoading,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonInput,
    IonButton,
    modalController,
    IonItem,
    IonAvatar,
    IonNote,
  } from '@ionic/vue';
  
  const hapticsImpactMedium = async () => {
    await Haptics.impact({ style: ImpactStyle.Medium });
  };

  export default defineComponent({
    name: 'LoginPage',
    components: {
      IonLoading,
      IonPage,
      IonHeader,
      IonToolbar,
      IonTitle,
      IonContent,
      IonInput,
      IonButton,
      Swiper,
      SwiperSlide,
      IonItem,
      IonAvatar,
      IonNote,
    },
    setup(){
      return {
        modules: [Pagination],
      };
    },
    data() {
      return {
        currentUser: null as User | null,
        briefs: [] as Array<any> | null,
        loginPhoneNumber: '',
        countryPrefix: '41',
        countryFlag: 'https://zmqrqcnayarsupofugun.supabase.co/storage/v1/object/public/system/country-flags/switzerland.png',
        formState: {
          type: 'success',
          content: '',
        },
        loadingState: {
          formSubmission: false,
        }
      }
    },
    watch: {
      currentUser(newVal) {
        console.log("watch",newVal);
      }
    },
    mounted() {
      this.checkForActiveUser();
      // this.$nextTick(function () {
      //   this.$refs.autofocus.click();
      //   console.log(this.$refs.autofocus);
      // })
    },
    methods: {
      checkForActiveUser() {
        if (!supabase) return;  

        supabase.auth.onAuthStateChange((event, session) => {
          if (event === 'SIGNED_OUT') {
            console.log('state change: user logged out');
            this.currentUser = null;
          } else if (event === 'SIGNED_IN' && session) {
            console.log('state change: user logged in');
            this.currentUser = session.user;
          } else {
            console.log('new auth state change event captured: ');
            console.log(event, session);
          }
        })
      },
      normalizePhoneNumber(number:string){
        number = number.replaceAll(" ", "").replaceAll('+','');
        if(number.startsWith("0")){
          number = number.replace("0", '');
        }
        number = `${this.countryPrefix}${number}`;
        console.log(number);
        
        return number.toString();
      },
      otpSendCode(){
        if (!supabase) return;
        this.loadingState.formSubmission = true;
        supabase.auth.signIn({
            phone: this.normalizePhoneNumber(this.loginPhoneNumber),
        })
        .then(response => {
              if (response.error !== null) {
                throw response.error;
              } else {
                // this.formState.type = 'success';
                // this.formState.content = 'SMS sent successfully...';
                this.loadingState.formSubmission = false;
                hapticsImpactMedium();
                this.$router.push(`/login/token/?phone=${this.normalizePhoneNumber(this.loginPhoneNumber)}`);

                // this.dismissLoading();
              }
            })
            .catch(error => {
              this.formState.type = 'error';
              this.formState.content = error.message ? error.message : error;
              this.loadingState.formSubmission = false;
              hapticsImpactMedium();

              // this.dismissLoading();
            });
      },
      async openNameModal(idTable:string, id_name:('doctor_id' | 'gender_id' | 'country_id' | 'basic_insurance_id' | 'supplement_insurance_id'),name:('doctor_name' | 'gender_name' | 'country_name' | 'basic_insurance_name' | 'supplement_insurance_name'), table:string) {
        console.log('open selectModal', id_name, table);
        
        const modal = await modalController.create({
          componentProps: {
            table: "countries",
            id: 'country_id',
            title: 'Land',
            filter: 'is_active',
            filterValue: true
          },
          component: SelectModal,
          canDismiss: true,
        });
        this.loadingState.formSubmission = false;

        modal.present();

        const { data, role } = await modal.onWillDismiss();

        if (role === 'confirm') {
          console.log(data);
          this.convertId(data, 'countries')
        }
      },
      async convertId(id:any, table:string){
        if (!supabase) return;
        const { data, error } = await supabase
          .from(table)
          .select()
          .eq('id', id)
        if (error) {
          console.error(error);
          return
        }
        if (data){
          this.countryPrefix = data[0].phone_prefix;
          this.countryFlag = data[0].flag_url;

        } 

      },
    }
  })
  