
  import { defineComponent } from 'vue';
  import {
    // loadingController,
    IonLoading,
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonInput,
    IonButton,
    IonLabel,
    IonItem,
    IonCheckbox,
  } from '@ionic/vue';
  import { supabase } from "@/supabase"
  import { User } from '@supabase/gotrue-js/src/lib/types'  
  import { Haptics, ImpactStyle } from '@capacitor/haptics';

  const hapticsImpactMedium = async () => {
    await Haptics.impact({ style: ImpactStyle.Medium });
  };

  export default defineComponent({
    name: 'LoginPage',
    components: {
      IonLoading,
      IonPage,
      IonHeader,
      IonToolbar,
      IonTitle,
      IonContent,
      IonInput,
      IonButton,
      IonLabel,
      IonItem,
      IonCheckbox,
    },
    data() {
      return {
        currentUser: {} as User | null,
        firstName: '',
        lastName: '',
        terms: false,
        termsTimestamp: '',
        profilePublic: [] as any,
        writeData: [] as Array<any> | null,
        formState: {
          type: 'success',
          content: '',
        },
        loadingState: {
          formSubmission: false,
        }
      }
    },
    watch: {
      currentUser(newVal) {
        console.log(newVal);
      }
    },
    // mounted() {
    // },
    methods: {
      async addName(){
        if (!supabase) return;
        this.loadingState.formSubmission = true;

        this.currentUser = supabase.auth.user();
        if (!this.currentUser) return;
        const { data, error } = await supabase
          .from('profiles')
          .update({ 'firstname': this.firstName, 'lastname': this.lastName })
          .match({ 'id': this.currentUser.id });
        
          const { data: dataPrivate, error: errorPrivate } = await supabase
          .from('profile_private_data')
          .update({'terms_accepted_at': this.termsTimestamp})
          .match({ 'id': this.currentUser.id });


        if (data && dataPrivate) {
          this.writeData = data;
          this.loadingState.formSubmission = false;
          hapticsImpactMedium();
          this.$router.push('/');
        }
        if (error || errorPrivate) {
          console.error(error);
        }
      },
      logoutUser() {
        if (!supabase) return;
        supabase.auth.signOut();
      },
      tcTimestamp(){
        if (this.terms){
          this.termsTimestamp = new Date().toISOString();
        }
        else{
          this.termsTimestamp = '';
        }
      }
    }
  })
  