
  import { defineComponent } from 'vue';
  import { supabase } from '@/supabase';
  import { User } from '@supabase/gotrue-js/src/lib/types';

  
  export default defineComponent({
    name: 'AvatarContainer',
    props: {
    id: String
  },
    data() {
      return {
        error: false,
        avatarUrl: '' as any,
        currentUser: {} as User | null,
      }
    },
    async created() {
      this.getAvatarUrl();
    },
    methods:{
        async downloadAvatar(url:string) {
        if (!supabase) return;
        this.currentUser = supabase.auth.user();
        // console.log(this.currentUser);
        if (!this.currentUser) return;
        // const { data, error } = await supabase
        //   .from('profiles')
        //   .select()
        //   .eq('id', this.currentUser.id)
        const { data, error } = await supabase
          .storage
          .from('avatars')
          .download(url +'?updated='+Date.now())
          
        if (error !== null) {
            console.log(error);
        }
        if (data !== null) {
            this.avatarUrl = URL.createObjectURL(data);
            console.log('🙋‍♀️ avatar updated');
        }
      },
      async getAvatarUrl(){
        if (!supabase) return;
        this.currentUser = supabase.auth.user();
        if (!this.currentUser) return;
        const { data, error } = await supabase
          .from('profiles')
          .select()
          .eq('id', this.currentUser.id)
        if (error) {
          console.log(error);
          return;
        }
        if (data) {
          this.downloadAvatar(data[0].avatar_url);
          console.log('avatar url',data[0].avatar_url);
        }
      }
    }
  });
  