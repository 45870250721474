import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import LoginPage from '../views/auth/Login.vue'
import AccountPage from '../views/Account.vue'
import LoginNamePage from '../views/auth/Name.vue'
import LoginTokenPage from '../views/auth/Token.vue'


import { supabase } from "../supabase";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/tab1'
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/tab1'
      },
      {
        path: 'tab1',
        component: () => import('@/views/Tab1Page.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2Page.vue'),
        meta: {
          requiresAuth: true,
        }
      },
      {
        path: 'tab3',
        component: () => import('@/views/Tab3Page.vue'),
        meta: {
          requiresAuth: true,
        }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
  },
  {
    path: '/login/name',
    name: 'Name',
    component: LoginNamePage,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/login/token',
    name: 'Token',
    component: LoginTokenPage,
    meta: {
      requiresAuth: false,
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: AccountPage,
    meta: {
      requiresAuth: true,
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// check if user is logged in and redirect to correct page
router.beforeEach(async (to, from, next) => {
  // get current user info
  if (!supabase){
    next('/login');
    return;
  }
  const currentUser = supabase.auth.user();
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  // if user is not logged in and requires auth, redirect to login page
  if(requiresAuth && !currentUser) {
    console.log('redirecting to login', requiresAuth, currentUser);
    const { error } = await supabase.auth.signOut()
    if (error) console.log('error', error)
    next('/login');    
  }
  else if(!requiresAuth && currentUser){
    console.log('redirecting to /', requiresAuth, currentUser);
    next("/");
  } 
  else{
    console.log('redirecting to next', requiresAuth, currentUser);
    next();
  } 
})

async function authChange(){
  if (!supabase) return;
  const session = supabase.auth.session()
  console.log('session',session);
  
  if (!session) return
  const { user, error } = await supabase.auth.api.getUser(session?.access_token)
  if (error) {
    const { error } = await supabase.auth.signOut()
    if (error) console.log('error', error)
    router.push('/login')
  }
  console.log('refreshed session',user,error);
  
}
authChange();


export default router
